import "./Navbar.css"
import logo_image from "../../resources/logo.png"
import {NavLink,Link} from 'react-router-dom'
function Navbar(props) {
    return (<div>
<nav className="navbar  navbar-expand-xl navbar-light bg-light">
  <div className="container-fluid">
    <Link to="/" className="navbar-brand not-mobile_brand_icon"><img width="120" src={logo_image} /></Link>  <div className="mobile inline-blockclass"><a id="phone_icon_mobile" href="tel:1818-503-9697"><i id="phone-icon" className="fa-solid fa-phone"></i></a> <a  id="map_icon_mobile"  href="https://goo.gl/maps/RA26rxGqXVsE5Chx7"><i  class="fa-solid fa-location-dot"></i></a></div>

    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mb-lg-0">
        <li className="nav-item">
        <NavLink className="nav-link" to="/" end>Home</NavLink>
        </li>
        <li className="nav-item dropdown">
          
          <NavLink id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" className="nav-link dropdown-toggle" to="/services" end>Services</NavLink>
          
          <ul className="dropdown-menu overflow_show" aria-labelledby="navbarDropdown">
            <li><Link className="dropdown-item item-padding" to="/Completefamilycare">Complete Family Care</Link></li>
            <li><Link className="dropdown-item item-padding" to="/Cosmeticdentistry">Cosmetic Dentistry</Link></li>
            <li><Link className="dropdown-item item-padding" to="/Crownsbridges">Crowns and Bridges</Link></li>
            <li><Link className="dropdown-item item-padding" to="/dentalimplants">Dental Implants</Link></li>
            <li><Link className="dropdown-item item-padding" to="/rootcanals">Root Canals</Link></li>
            <li><Link className="dropdown-item item-padding" to="/dentalhygiene">Dental Hygiene</Link></li>
            <li><Link className="dropdown-item item-padding" to="/dentures">Dentures</Link></li>
            <li><Link className="dropdown-item item-padding" to="/veneers">Veneers</Link></li>
            <li><Link className="dropdown-item item-padding" to="/emergencycare">Emergency Care</Link></li>
            <li><Link className="dropdown-item item-padding" to="/wisdomteeth">Wisdom Teeth Extraction</Link></li>
            <li><Link className="dropdown-item item-padding" to="/teethwhitening">Teeth Whitening</Link></li>
            <li><Link className="dropdown-item item-padding" to="/photography">Intra-Oral Photography</Link></li>
            <li><Link className="dropdown-item item-padding" to="/tmj">TMJ/TMD Assessment</Link></li>
           
            
          </ul>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Patient Info
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link className="dropdown-item item-padding" to="/gallery">Gallery</Link></li>
            <li><Link className="dropdown-item item-padding" to="/financing">Financing</Link></li>
            </ul>
        </li>
        <li className="nav-item">
        <NavLink className="nav-link" to="/about">About Us</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="/contact">Contact</NavLink>
        </li>
       
      </ul>
      
    </div>
  </div>
</nav>


    </div>)
  


}

export default Navbar