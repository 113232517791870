import "./Dentures.css"
import HeaderTitle from "../../../HeaderTitle/HeaderTitle"
import {Fragment} from "react"
import OneCallAway from "../../OneCallAway/OneCallAway"
import { Helmet } from 'react-helmet-async';

function Dentures(props) {
    return (<Fragment>
           <Helmet>
<title>Dentures</title>
<meta name='description' content='Dentures' />
<meta name='keywords' content='dentures,dentist,family dentistry,implants,gallery,family care,cosmetic dentistry,prophylaxis, cleaning, deep cleaning, tartar,dental implants,dental veneers,veneers'/>
<meta name='summary' content='dentures'/> 
<meta name='description' content='dentures'/>
<meta name="title" content="dentures"></meta>
</Helmet>
        <HeaderTitle title="Dentures"/>
        <div className="content_holder_services">
           
          
            <div className="services_actual_page_header" >Aben Veinberg DDS provides dentures and complete dental care for Los Angeles, CA and the surrounding areas.</div>
           <div className="services_actual_page_text">Dentures are removable replacements for missing teeth typically made out of an acrylic resin which at times incorporate porcelain or metal for additional structural support. There are two main types of dentures. Both Complete Dentures and Partial Dentures are finely crafted and custom-fitted. If you properly maintain your dentures they will appear natural and provide an adequate replacement. Additionally, dentures help strengthen muscles controlling your expressions that require the support of your teeth, rid you of pronunciation problems caused by missing teeth and aid with chewing.</div>
           <span className="small_title_services">How are dentures accomplished?</span>
           <div className="services_actual_page_text">In some cases surgery is necessary to improve the bony ridges that stabilize your dentures. Sometimes it is required for teeth to be extracted.

The procedure begins with a wax bite impression of your mouth that will give your dentist exacting measurements. A try-in appointment will fine tune color, shape, and custom-fit. After your final dentures are fabricated, they will be fit and you will be informed of their required care. </div>
            </div>
            
        <OneCallAway/>
    </Fragment>)
  }

export default Dentures