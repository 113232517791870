import "./RootCanals.css"
import HeaderTitle from "../../../HeaderTitle/HeaderTitle"
import {Fragment} from "react"
import OneCallAway from "../../OneCallAway/OneCallAway"
import { Helmet } from 'react-helmet-async';

function RootCanals(props) {
    return (<Fragment>
        <HeaderTitle title="Root Canals"/>
        <Helmet>
<title>Root Canals</title>
<meta name='description' content='root canal' />
<meta name='keywords' content='root,root canal,canal,dentist,family dentistry,implants,gallery,family care,cosmetic dentistry,prophylaxis, cleaning, deep cleaning, tartar,dental implants,dental veneers,veneers'/>
<meta name='summary' content='root canal'/> 
<meta name='description' content='root canal'/>
<meta name="title" content="root canal"></meta>
</Helmet>
        <div className="content_holder_services">
           
      
            <div className="services_actual_page_header">Need help immediately with your teeth? Don't worry Aben Veinberg DDS is ready for your dental problems.</div>
            <div className="services_actual_page_text">A root canal is one of the most common dental procedures performed, well over 14 million every year. This simple treatment can save your natural teeth and prevent the need of dental implants or bridges.</div>
           <div className="services_actual_page_text">At the center of your tooth is pulp. Pulp is a collection of blood vessels that helps to build the surrounding tooth. Infection of the pulp can be caused by trauma to the tooth, deep decay, cracks and chips, or repeated dental procedures. Symptoms of the infection can be identified as visible injury or swelling of the tooth, sensitivity to temperature or pain in the tooth and gums.</div>
           <div className="services_actual_page_text">If you experience any of these symptoms, your dentist will most likely recommend non-surgical treatment to eliminate the diseased pulp. This injured pulp is removed and the root canal system is thoroughly cleaned and sealed. Root canal therapy usually involves local anesthesia and may be completed in one or more visits depending on the treatment required. Success for this type of treatment occurs in about 90% of cases. If your tooth is not amenable to endodontic treatment or the chance of success is unfavorable, you will be informed at the time of consultation or when a complication becomes evident during or after treatment. Dr. Veinberg uses local anesthesia to eliminate discomfort. You will be able to drive home after your treatment, and you probably will be comfortable returning to your normal routine.</div>
           <video controls="true" muted="true" loop="true" id="implant_video" width="50%">
 <source src="https://s3.us-west-1.amazonaws.com/www.abenveinbergdds.com/videos/root_canal_video.mp4" />
 </video>

            </div>
        <OneCallAway/>
    </Fragment>)
  }

export default RootCanals