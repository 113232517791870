import "./ServicesPage.css"
import HeaderTitle from "../../HeaderTitle/HeaderTitle"
import {Fragment} from "react"
import {Link} from "react-router-dom"
import { Helmet } from 'react-helmet-async';

function ServicesPage(props) {
    return (<Fragment>
        <Helmet>
<title>Services</title>
<meta name='description' content='Services' />
<meta name='keywords' content='services,teeth whitening, dentist,family dentistry,implants,gallery,family care,cosmetic dentistry,prophylaxis, cleaning, deep cleaning, tartar,dental implants,dental veneers,veneers'/>
<meta name='summary' content='services'/> 
<meta name='description' content='services'/>
<meta name="title" content="services"></meta>
</Helmet>
        <HeaderTitle title="Services"/>
        <div id="services_page_container" className="container-fluid">
      <div className="row">
      <div id="services_long" className="col mt-4 font_color_grey">Aben Veinberg DDS provides dental services and treatments for the entire family in Los Angeles, CA and the surrounding areas. Please click one of the links below to learn more about that specific service or procedure. If you have any questions regarding a certain service or procedure or do not see something listed that you are interested in, feel free to give us a call for more information.
      
      </div>

      </div>

      <div className="row">

      <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12  services_home_title">
      <Link className="services_home_link font_color" to="/CompleteFamilyCare">Complete Family Care »</Link>
      <div className="divider">
			<span></span>
		</div>
        
        </div> 

        <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
        <Link className="services_home_link font_color " to="/Cosmeticdentistry">Cosmetic Dentistry »</Link>
        
        <div className="divider">
			<span></span>
		</div>
        
        </div> 

        <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
        <Link className="services_home_link font_color" to="/dentalhygiene">Dental Hygiene »</Link>
        
        <div className="divider">
			<span></span>
		</div>
        
        </div> 

      </div>


      <div className="row mt-xl-5 mt-lg-5 mt-md-5">

<div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
<Link className="services_home_link font_color" to="/teethwhitening">Teeth Whitening »</Link>
<div className="divider">
<span></span>
</div>
  
  </div> 

  <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
  <Link className="services_home_link font_color" to="/veneers">Dental Veneers »</Link>
  
  <div className="divider">
<span></span>
</div>
  
  </div> 

  <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
  <Link className="services_home_link font_color" to="/emergencycare">Emergency Care »</Link>
  
  <div className="divider">
<span></span>
</div>
  
  </div> 

</div>


<div className="row mt-xl-5 mt-lg-5 mt-md-5">

      <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
      <Link className="services_home_link font_color" to="/dentalimplants">Dental Implants »</Link>
      <div className="divider">
			<span></span>
		</div>
        
        </div> 

        <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
        <Link className="services_home_link font_color" to="/Crownsbridges">Crowns & Bridges »</Link>
        
        <div className="divider">
			<span></span>
		</div>
        
        </div> 

        <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
        <Link className="services_home_link font_color" to="/dentures">Dentures »</Link>
        
        <div className="divider">
			<span></span>
		</div>
        
        </div> 

      </div>


      <div className="row mt-xl-5 mt-lg-5 mt-md-5">

<div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
<Link className="services_home_link font_color" to="/tmj">TMJ/TMD Assessment »</Link>
<div className="divider">
<span></span>
</div>
  
  </div> 

  <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
  <Link className="services_home_link font_color" to="/photography">Intra-Oral Photography »</Link>
  
  <div className="divider">
<span></span>
</div>
  
  </div> 

  <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
  <Link className="services_home_link font_color" to="/rootcanals">Root Canals »</Link>
  
  <div className="divider">
<span></span>
</div>
  
  </div> 

</div>



    </div>
        
    </Fragment>)
  }

export default ServicesPage