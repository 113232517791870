import "./top_portion.css"
import {useRef} from "react"

function TopPortion(props) {

const phoneRef = useRef(null)
const mapRef = useRef(null)

  function clicktele () {

    phoneRef.current.click()

  }

  function clickmap () {
    mapRef.current.click()
  }

    return (<div className="not-mobile" id="top_portion" >
        <div id="phone_number_topz" onClick={clicktele} class="phone_number_top"><i id="phone-icon" className="fa-solid fa-phone"></i>(818) 503-9697</div>
        <a ref={phoneRef} className="hidden_links" href="tel:1818-503-9697" ></a>
        <a ref={mapRef} className="hidden_links" href="https://goo.gl/maps/RA26rxGqXVsE5Chx7"></a>

        <div onClick={clickmap} id="address_topz" class="address_top"> <i id="location-icon" className="fa-solid fa-location-dot"></i>6738 Lankershim Blvd, North Hollywood, CA, 91606</div>

       
       


    </div>)
  }

export default TopPortion

