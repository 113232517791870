import "./Financing.css"
import HeaderTitle from "../../HeaderTitle/HeaderTitle"
import {Fragment} from "react"
import OneCallAway from "../OneCallAway/OneCallAway"
import care from "../../resources/carecredit.png"
import alpha from "../../resources/alphaeon.jpg"
import { Helmet } from 'react-helmet-async';

function Financing(props) {
    return (<Fragment>
<Helmet>
<title>Financing</title>
<meta name='description' content='Financing' />
<meta name='keywords' content='dentist,family dentistry,implants,financing, care credit,alphaeon credit '/>
<meta name='summary' content='financing'/> 
<meta name='description' content='financing'/>
<meta name="title" content="Financing for dental procedures"></meta>
</Helmet>
        <HeaderTitle title="Financing"/>
        <div className="content_holder_services">
           
           <div className="services_actual_page_text">Here at Aben Veinberg DDS, we highly appreciate the emotional and financial investment to proceed with comprehensive dentistry. During your consultation, our doctors and staff will review your treatment plan with you and provide a description of the proposed treatment. We will also discuss your investment prior to any treatment, along with several options for your convenience.</div>
           <div className="services_actual_page_text">We can help you maximize your benefits. We accept all dental insurance programs. Our goal is to maximize your insurance benefits and make any remaining balance due easily affordable. We Accept: cash, checks, VISA, MasterCard and Discover</div>
           <div className="services_actual_page_text">Special financing options available with approved credit through CareCredit and Alphaeon </div>
           <span className="small_title_services">Apply for financing:</span>
           <a href="https://www.carecredit.com/apply/" ><img id="care_image" src={care} /></a>
           <a href="https://go.alphaeoncredit.com/credit-portal/apply" ><img id="alphaeon_image" className="" src={alpha} /></a>


        </div>
        
        <OneCallAway/>
    </Fragment>)
  }

export default Financing