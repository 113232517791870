import "./CrownsBridges.css"
import HeaderTitle from "../../../HeaderTitle/HeaderTitle"
import {Fragment} from "react"
import OneCallAway from "../../OneCallAway/OneCallAway"
import { Helmet } from 'react-helmet-async';

function CrownsBridges(props) {
    return (<Fragment>
      <Helmet>
<title>Crowns and Bridges</title>
<meta name='description' content='Crowns and Bridges' />
<meta name='keywords' content='dentist,family dentistry,implants,gallery,family care,cosmetic dentistry,crowns,bridges'/>
<meta name='summary' content='Crowns and Bridges'/> 
<meta name='description' content='Crowns and Bridges'/>
<meta name="title" content="Crowns and Bridges"></meta>
</Helmet>
        <HeaderTitle title="Crowns and Bridges"/>
        <div className="content_holder_services">
            <div className="services_actual_page_header" >Quality crowns and bridges to keep you smiling all day long.</div>
           <div className="services_actual_page_text">At Aben Veinberg DDS, we are a complete family dentistry practice. We go above and beyond to provide top of the line patient care for you and your family. There is no need to go from practice to practice, when we can handle all members of your family. With our convenient appointment times and flexible staff, we can accommodate even the busiest schedules.</div>
           <span className="small_title_services">Crowns</span>
           <div className="services_actual_page_text">Grinding your teeth, an improper bite, age, fillings and tooth decay can all be contributing factors in the wearing down, cracking or breakage of your teeth. Dental crowns cover the entire visible surface of your affected tooth and add strength, durability and tooth stability.</div>
           <span className="small_title_services">How are Crowns Attached to your Tooth?</span>
           <div className="services_actual_page_text">Your dentist will make an impression of the tooth and a dental laboratory will create the crown. You will typically leave the office with a temporary crown to wear while the permanent crown is being fabricated – this takes about two weeks. The permanent crown is then cemented onto your tooth. Typically, only two visits are required for this type of procedure. Often, a preliminary restoration of your tooth may be needed before a crown can be placed. To stabilize your tooth, a buildup may first be put in place prior to placing a crown due to the loss of original tooth structure.</div>
           <div className="services_actual_page_text">There are basically three types of crowns, those made of gold, ceramic crowns and ceramic-veneered gold crowns. Gold and metal-ceramic crowns are extremely durable and are normally used in molars, where the forces from chewing and grinding are most prevalent. All ceramic crowns are used for front and back teeth. They best resemble the natural tooth color.</div>
           <video controls="true" muted="true" loop="true" id="implant_video" width="50%">
             <source src="https://s3.us-west-1.amazonaws.com/www.abenveinbergdds.com/videos/crownvideo.mp4" />
            </video> 
            <div id="divider_crowns_bridges" ></div>
            <span className="small_title_services">Bridges</span>
            <div className="services_actual_page_text">A dental bridge is a false tooth, known as a pontic, which is fused between two porcelain crowns to fill in the area left by a missing tooth. This is known as a fixed bridge. This procedure is used to replace one or more missing teeth. Fixed bridges cannot be taken out of your mouth as you might do with removable partial dentures.</div>
            <div className="services_actual_page_text">Bridges can reduce your risk of gum disease, help correct some bite issues and even improve your speech. Bridges require your commitment to serious oral hygiene, but will last as many as ten years or more.</div>
            <span className="small_title_services">Overview of the dental bridge procedure.</span>
            <div className="services_actual_page_text">If you have a space from a missing tooth, a bridge will be custom made to fill in the space with a false tooth. The false tooth is attached to the two other adjacent teeth around the space – bridging them together.</div>
            <span className="small_title_services">How is the dental bridge accomplished?</span>
            <div className="services_actual_page_text">Your dentist will prepare your teeth on either side of the space for the false tooth. You will be given a mild anesthetic to numb the area, and your dentist will remove an area of each abutment (teeth on either side of the space) to accommodate for the thickness of the crown.</div>
            <div className="services_actual_page_text">The dentist will then take an impression, which will serve as the model from which the bridge, false tooth and crowns will be fabricated by a dental laboratory. A temporary bridge will be placed for you to wear while your bridge is being fabricated until your next visit. This temporary bridge will serve to protect your teeth and gums.</div>
            <div className="services_actual_page_text">Your dentist may have you use a flipper appliance. A flipper is a false tooth to temporarily take the place of a missing tooth before the permanent bridge is placed. A flipper can be attached via either a wire or a plastic piece that fits in the roof of your mouth. Flippers are meant to be a temporary solution while awaiting the permanent bridge.</div>
            <div className="services_actual_page_text">On your second appointment, the temporary bridge will be removed. Your new permanent bridge will be fitted, checked and adjusted for any bite discrepancies. Your new bridge will then be cemented to your teeth.</div>
            <span className="small_title_services">There are three types of dental bridges:</span>
            <div className="services_actual_page_text"><b>Traditional Fixed Bridge:</b> A dental bridge is a false tooth, known as a pontic, which is fused between two porcelain crowns to fill in the area left by a missing tooth. This is known as a fixed bridge. This procedure is used to replace one or more missing teeth. Fixed bridges cannot be taken out of your mouth as you might do with removable partial dentures.</div>
            <div className="services_actual_page_text"><b>Resin Bonded Bridges:</b> A resin bonded bridge is primarily used for your front teeth. Less expensive, this bridge is best used when the abutment teeth are healthy and don’t have large fillings. The false tooth is fused to bands that are bonded to the abutment teeth with a resin which is hidden from view. This type of bridge reduces the amount of preparation on the adjacent teeth.</div>
            <div className="services_actual_page_text"><b>Cantilever Bridges:</b> Cantilever bridges are used when there are teeth on only one side of the open space. This procedure involves anchoring the false tooth to one side over one or more natural and adjacent teeth.</div>



            <video controls="true" muted="true" loop="true" id="implant_video" width="50%">
 <source src=" https://s3.us-west-1.amazonaws.com/www.abenveinbergdds.com/videos/bridge_video.mp4" />
 </video>
        </div>
     
        <OneCallAway/>
    </Fragment>)
  }

export default CrownsBridges