import "./Footer.css"
import logo_image from "../../resources/logo.png"
import {useRef} from "react"

function Footer(props) {

    const phoneRef = useRef(null)
    const mapRef = useRef(null)
    
      function clicktelezz () {
    
        phoneRef.current.click()
    
      }
    
      function clickmapzz () {
        mapRef.current.click()
      }


    



    return (<div id="footer_container" className="container-fluid" >
        
        <div className="row align-top">
        <div className="col col-xs-12 col-s-12 text-center ">
        <h4>Contact Us</h4>

            </div>
            <div style={{margin:"auto"}}  className="col text-center d-none d-xl-block ">
              
                </div>
            <div  className="col col-xs-12 col-s-12 text-center ">
            <h4>Office Hours</h4><br/>
          
            
            </div>

        </div>
        <div className="row">
            <div style={{margin:"auto",textAlign:"center"}} className="col col-xs-12 col-s-12 ">
        
                <span onClick={clicktelezz} id="phone_footerzzz">(818) 503-9697</span> <br/>
                <span onClick={clickmapzz} id="address_footerzzz">6738 Lankershim Blvd, <br/> North Hollywood, CA, 91606</span>
                <a ref={phoneRef} className="hidden_links" href="tel:1818-503-9697" ></a>
                <a ref={mapRef} className="hidden_links" href="https://goo.gl/maps/RA26rxGqXVsE5Chx7"></a>

            </div>
            <div style={{marginLeft:"auto",marginRight:"auto",marginTop:"-50px"}}  className="col text-center d-none d-xl-block ">
                <img width="140" src={logo_image}/>
                </div>
            <div style={{margin:"auto",textAlign:"center"}} className="col col-xs-12 col-s-12 ">
      
            Monday: 9am–6pm<br/>
            Tuesday: 9am–6pm<br/>
            Wednesday: 9am–6pm<br/>
            Thursday: 9am–6pm<br/>
            Friday: 9am–6pm<br/>
            Saturday: Closed<br/>
            Sunday: Closed
            
            </div>
        </div>
        <div className="row mt-2">
            <div className="col text-center"> {new Date().getFullYear()} Aben Veinberg DDS ©</div>
        </div>
       


    </div>)
  }

export default Footer