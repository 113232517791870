import "./ContactUs.css"
import logo_image from "../../resources/logo.png"
import HeaderTitle from "../../HeaderTitle/HeaderTitle"
import {Fragment,useRef} from "react"
import { Helmet } from 'react-helmet-async';

function ContactUs(props) {

    const phoneRefzzzz = useRef(null)
    const mapRefzzzz = useRef(null)
    
      function clicktelezzz () {
    
        phoneRefzzzz.current.click()
    
      }
    
      function clickmapzzz () {
        mapRefzzzz.current.click()
      }

    return (<Fragment>
<Helmet>
<title>Contact Us</title>
<meta name='description' content='Contact Us' />
<meta name='keywords' content='dentist,family dentistry,implants'/>
<meta name='summary' content='It shows the location, phone number, hours of business'/> 
<meta name='description' content='location, phone number'/>
<meta name="title" content="contact us"></meta>
</Helmet>


        <HeaderTitle title="Contact Us"/>
        <div className="container-fluid mt-3 mb-2">
            <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-s-12 col-xs-12">
            <iframe id="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3300.1605605625587!2d-118.38964428573347!3d34.19337381772887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c295d4863eee0d%3A0xef35e3efad2f464!2sAben%20Veinberg%20DDS!5e0!3m2!1sen!2sus!4v1667331698213!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-s-12 col-xs-12">
            <span id="get_in_touch" >Get in Touch</span>
            <div className="row mt-3">
                <div className="col">
                    <span className="touch_title">Location</span><br/>
                    <p onClick={clickmapzzz} className="touch_body cursor_pointer_onhover">6738 Lankershim Blvd, <br/> North Hollywood, CA 91606</p>
                </div>
                <div className="col">
                    <span className="touch_title">Contact Us</span><br/>
                    <p onClick={clicktelezzz} className="touch_body cursor_pointer_onhover">(818) 503-9697</p></div>
                    <a ref={phoneRefzzzz} className="hidden_links" href="tel:1818-503-9697" ></a>
                     <a ref={mapRefzzzz} className="hidden_links" href="https://goo.gl/maps/RA26rxGqXVsE5Chx7"></a>
            </div>

            <div className="row mt-2">
                <div className="col">
                    <span className="touch_title">Our Hours</span><br/>
                    <p className="touch_body"> Monday: 9am–6pm<br/><br/>
            Tuesday: 9am–6pm<br/><br/>
            Wednesday: 9am–6pm<br/><br/>
            Thursday: 9am–6pm<br/><br/>
            Friday: 9am–6pm<br/><br/>
            Saturday: Closed<br/><br/>
            Sunday: Closed</p>
                </div>
                <div className="col">
                    <span className="touch_title">Social Media</span><br/>
                    <p className="touch_body"></p></div>
            </div>
            </div>

            </div>
        </div>
    </Fragment>)
  }

export default ContactUs