import "./Aboutus.css"
import HeaderTitle from "../../HeaderTitle/HeaderTitle"
import {Fragment} from "react"
import OneCallAway from "../OneCallAway/OneCallAway"
import image from "../../resources/dentist.jpeg"
import { Helmet } from 'react-helmet-async';

function AboutUs(props) {
    return (<Fragment>
<Helmet>
<title>About Us</title>
<meta name='description' content='About us page' />
<meta name='keywords' content='dentist,family dentistry,implants'/>
<meta name='summary' content='general dentist'/> 
<meta name='description' content='dentist in los angeles'/>
<meta name="title" content="about us page"></meta>
</Helmet>

        <HeaderTitle title="About Us"/>
        <div className="container-fluid padding_for_container">
        <div className="row">
      <div className="col-xl-6 col-lg-6 col-md-6 col-s-12 col-xs-12"><img id="image_aben" src={image}/></div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-s-12 col-xs-12 gy-5">
        <div className="services_actual_page_header">Dr. Aben Veinberg</div>
        <div className="services_actual_page_text">Aben Veinberg is a board eligible General Dentist. He has been working as a dentist in Los Angeles, CA since 1994. He speaks English, Russian and some Spanish. </div>
      <div></div>
      </div>
        </div>
        
        </div>
        <OneCallAway/>
    </Fragment>)
  }

export default AboutUs