import "./CompleteFamilyCare.css"
import HeaderTitle from "../../../HeaderTitle/HeaderTitle"
import {Fragment} from "react"
import OneCallAway from "../../OneCallAway/OneCallAway"
import { Helmet } from 'react-helmet-async';

function CompleteFamilyCare(props) {
    return (<Fragment>
      <Helmet>
<title>Complete Family Care</title>
<meta name='description' content='Complete Family Care' />
<meta name='keywords' content='dentist,family dentistry,implants,gallery, family care'/>
<meta name='summary' content='family care'/> 
<meta name='description' content='Complete family care for the whole family'/>
<meta name="title" content="Family Care for the whole family"></meta>
</Helmet>
        <HeaderTitle title="Complete Family Care"/>
        <div className="content_holder_services">
           
          
            <div className="services_actual_page_header" >Your family dentist providing complete family care for Los Angeles, CA and the surrounding areas.</div>
           <div className="services_actual_page_text">At Aben Veinberg DDS, we are a complete family dentistry practice. We go above and beyond to provide top of the line patient care for you and your family. There is no need to go from practice to practice, when we can handle all members of your family. With our convenient appointment times and flexible staff, we can accommodate even the busiest schedules.</div>
            
            
    
        </div>
        <OneCallAway/>
    </Fragment>)
  }

export default CompleteFamilyCare