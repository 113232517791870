import "./Imaging.css"
import HeaderTitle from "../../../HeaderTitle/HeaderTitle"
import {Fragment} from "react"
import OneCallAway from "../../OneCallAway/OneCallAway"
import { Helmet } from 'react-helmet-async';

function Imaging(props) {
    return (<Fragment>
        <HeaderTitle title="Intra-Oral Photography"/>
        <Helmet>
<title>Intra-Oral Photography</title>
<meta name='description' content='Imaging' />
<meta name='keywords' content='x-rays,dentist,family dentistry,implants,gallery,family care,cosmetic dentistry,prophylaxis, cleaning, deep cleaning, tartar,dental implants,dental veneers,veneers'/>
<meta name='summary' content='x-rays'/> 
<meta name='description' content='x-rays'/>
<meta name="title" content="x-rays"></meta>
</Helmet>
        <div className="content_holder_services">
           
          
            <div className="services_actual_page_header" >Modern and state of the art imaging technology at Aben Veinberg DDS.</div>
           <div className="services_actual_page_text">We offer you a variety of advanced dental imaging technologies in the office. These imaging technologies allow us to improve our diagnosis capabilities and improve accuracy of treatments.</div>
         </div>  
        <OneCallAway/>
    </Fragment>)
  }

export default Imaging