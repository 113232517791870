import "./HomePage.css"
import Banner from "../../resources/banner.png"
import SmilingWoman from "../../resources/smiling_woman.jpg"
import {Link} from "react-router-dom"
import OneCallAway from "../OneCallAway/OneCallAway"
import { Helmet } from 'react-helmet-async';
import cspecial from '../../resources/combo special.png'

function HomePage(props) {
    return (<div>
<Helmet>
<title>Aben Veinberg DDS</title>
<meta name='description' content='homepage' />
<meta name='keywords' content='dentist,family dentistry,implants,gallery'/>
<meta name='summary' content='Showcasing previous work'/> 
<meta name='description' content='Imaging showing previous work done'/>
<meta name="title" content="gallery"></meta>
</Helmet>
        <img id="home_banner" src={Banner}/>
        <div id="top_portion_homepage" className="container-fluid mb-5" >
        
        <div className="row">
        <div className="col-lg-6 col-xl-6 col-md-6 col-s-12 col-xs-12"><img id="smiling_woman_img" src={SmilingWoman} /></div>
        <div className="col-lg-6 col-xl-6 col-md-6 col-s-12 col-xs-12"><ul className="checkmarks_font">
          <li>A wide range of dental services</li>
          <li>We are a family clinic</li>
          <li>Individual approach to patient</li>
          <li>Patient safety treatment</li>
          <li>Training of medical personnel</li>
         </ul></div>
        </div>

      <div className="row mt-3">
        <div className="col"></div>
        <h4 id="who_we_are_title">- Who We Are -</h4>
        </div>



     
        <div className="row">
        <div id="who_are_text" className="col">
           <p>Aben Veinberg DDS is your #1 choice when looking for a dentist in Los Angeles, CA. Our office is dedicated to providing dental care for the entire family, offering a warm and welcoming environment for children and adults alike. We will do everything possible to make your visits comfortable for you and your family, and will strive to complete your treatments efficiently and as quickly as possible. We look forward to welcoming you into our dental family.
‍
<br/> <br/> We love what we do, and it shows on our faces – it seems we’re always smiling around here. You’ll find our team is friendly and upbeat and we’ll do our best to make your visit comfortable, and even fun. All the while we’ll be providing you with the highest quality dental care. Don’t be surprised if it’s the best time you’ve had at a dental office!</p>
</div>
    </div>

   



    </div>
    <div id="services_homepage" className="container-fluid">
      <div className="row">
      <div id="small_title_services" className="col">Our Services
      
      </div>

      </div>
      <div className="row">
      <div id="services_what" className="col mt-4">What We Do
      
      </div>

      </div>


      <div className="row">
      <div id="services_long" className="col mt-4">Aben Veinberg DDS provides dental services and treatments for the entire family in Los Angeles, CA and the surrounding areas. Please click one of the links below to learn more about that specific service or procedure. If you have any questions regarding a certain service or procedure or do not see something listed that you are interested in, feel free to give us a call for more information.
      
      </div>

      </div>

      <div className="row mt-5">

      <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12  services_home_title">
      <Link className="services_home_link" to="/CompleteFamilyCare">Complete Family Care »</Link>
      <div className="divider">
			<span></span>
		</div>
        
        </div> 

        <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
        <Link className="services_home_link" to="/Cosmeticdentistry">Cosmetic Dentistry »</Link>
        
        <div className="divider">
			<span></span>
		</div>
        
        </div> 

        <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
   
        <Link className="services_home_link" to="/Crownsbridges">Crowns & Bridges »</Link>
        <div className="divider">
			<span></span>
		</div>
        
        </div> 

      </div>


      <div className="row mt-xl-5 mt-lg-5 mt-md-5">

<div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
<Link className="services_home_link" to="/rootcanals">Root Canals »</Link>

<div className="divider">
<span></span>
</div>
  
  </div> 

  <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">

  <Link className="services_home_link" to="/dentalimplants">Dental Implants »</Link>
  
  <div className="divider">
<span></span>
</div>
  
  </div> 

  <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
  <Link className="services_home_link" to="/emergencycare">Emergency Care »</Link>
  
  <div className="divider">
<span></span>
</div>
  
  </div> 

</div>


<div className="row mt-xl-5 mt-lg-5 mt-md-5">

      <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
      <Link className="services_home_link" to="/veneers">Veneers »</Link>
      <div className="divider">
			<span></span>
		</div>
        
        </div> 

        <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
        <Link className="services_home_link" to="/dentalhygiene">Dental Hygiene »</Link>
        
        <div className="divider">
			<span></span>
		</div>
        
        </div> 

        <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
        <Link className="services_home_link" to="/dentures">Dentures »</Link>
        
        <div className="divider">
			<span></span>
		</div>
        
        </div> 

      </div>


      <div className="row mt-xl-5 mt-lg-5 mt-md-5">

<div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">

<Link className="services_home_link" to="/teethwhitening">Teeth Whitening »</Link>
<div className="divider">
<span></span>
</div>
  
  </div> 

  <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
  <Link className="services_home_link" to="/photography">Intra-Oral Photography »</Link>
  
  <div className="divider">
<span></span>
</div>
  
  </div> 

  <div className="col-xl-4 col-lg-4 col-md-4 col-s-12 col-xs-12 margin-top-collaspe services_home_title">
  <Link className="services_home_link" to="/tmj">TMJ/TMD Assessment »</Link>
  
  <div className="divider">
<span></span>
</div>
  
  </div> 

</div>



    </div>
    <div className="container-fluid">
    <div className="row">
  <div className="col text-center">
    <img className="special_home" src={cspecial}/>
  </div>
  
</div>
</div>


<OneCallAway/>
  
        </div>
  


       
   )
  }

export default HomePage