import "./DentalHygiene.css"
import HeaderTitle from "../../../HeaderTitle/HeaderTitle"
import {Fragment} from "react"
import OneCallAway from "../../OneCallAway/OneCallAway"
import { Helmet } from 'react-helmet-async';
import cspecial from "../../../resources/combo special.png"

function DentalHygiene(props) {
    return (<Fragment>

<Helmet>
<title>Dental Hygiene</title>
<meta name='description' content='Dental Hygiene' />
<meta name='keywords' content='dentist,family dentistry,implants,gallery,family care,cosmetic dentistry,prophylaxis, cleaning, deep cleaning, tartar'/>
<meta name='summary' content='Dental Hygiene'/> 
<meta name='description' content='Dental Hygiene'/>
<meta name="title" content="Dental Hygiene"></meta>
</Helmet>
        <HeaderTitle title="Dental Hygiene"/>
        <div className="content_holder_services">
           
          
            <div className="services_actual_page_header" >More modern and advanced dental cleanings for you and your family at Aben Veinberg DDS.</div>
           <div className="services_actual_page_text">We offer you a variety of advanced dental hygiene treatment and diagnosis options, including routine dental cleanings (Dental Prophylaxis) and deep cleanings (Scaling and Root Planing.)</div>
           <div className="services_actual_page_text"> During your visit, dentist will remove plaque from places your brush cannot reach. This includes between your teeth and along your gum line.</div>
           <div className="services_actual_page_text">Call Aben Veinberg DDS today to schedule a cleaning.</div>
           <img className="special_service" src={cspecial}/>
        </div>
       
        <OneCallAway/>
    </Fragment>)
  }

export default DentalHygiene