import "./TeethWhitening.css"
import HeaderTitle from "../../../HeaderTitle/HeaderTitle"
import {Fragment} from "react"
import OneCallAway from "../../OneCallAway/OneCallAway"
import { Helmet } from 'react-helmet-async';

function TeethWhitening(props) {
    return (<Fragment>
         <Helmet>
<title>Teeth Whitening</title>
<meta name='description' content='Teeth Whitening' />
<meta name='keywords' content='teeth whitening, dentist,family dentistry,implants,gallery,family care,cosmetic dentistry,prophylaxis, cleaning, deep cleaning, tartar,dental implants,dental veneers,veneers'/>
<meta name='summary' content='teeth whitening'/> 
<meta name='description' content='teeth whitening'/>
<meta name="title" content="teeth whitening"></meta>
</Helmet>
        <HeaderTitle title="Teeth Whitening"/>
        <div className="content_holder_services">
            <div className="services_actual_page_header">Looking for a brighter, whiter smile? You're in luck. Aben Veinberg DDS can help.</div>
           <div className="services_actual_page_text">Teeth whitening is used to correct discoloration of the teeth by removing the brown and yellow staining. Before the patient embarks on the home teeth whitening regimen, the dentist creates a custom mouth tray for the patient. The custom tray ensures that the correct amount of whitening solution is used and the patient’s teeth are properly exposed to the whitening solution. The custom mouth tray provides for a better fit than a one-size-fits-all mouth tray to help increase the margin of patient safety.</div>
           <div className="services_actual_page_text">The steps in the home teeth whitening regimen are fairly straightforward. The dentist’s office provides the patient with the materials and instructions and also answers questions the patient may have about the routine.</div>
           <div className="services_actual_page_text">A home whitening session can last for one to three hours, during which time you will have the tray and solution in your mouth. Depending upon your needs and desired results, your dentist may instruct you to keep the tray in your mouth overnight. Typically, you will follow your home teeth whitening regimen for seven to ten days.</div>
           <div className="services_actual_page_text">Teeth whitening is not a permanent procedure. Results can last for one or more years, depending upon your personal habits. In most cases, the whitening procedure is very effective and patients are pleased with the results. </div>
           <video controls="true" muted="true" loop="true" id="implant_video" width="50%">
 <source src="https://s3.us-west-1.amazonaws.com/www.abenveinbergdds.com/videos/teeth_whitening.mp4" />
 </video>

           </div>
        <OneCallAway/>
    </Fragment>)
  }

export default TeethWhitening