import logo from './logo.svg';
import './App.css';
import TopPortion from './Components/top_portion/top_portion';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import HomePage from './Components/HomePage/HomePage';
import ContactUs from './Components/ContactUs/ContactUs';
import CompleteFamilyCare from './Components/Services/CompleteFamilyCare/CompleteFamilyCare';
import {Route,Routes} from 'react-router-dom'
import Dentures from './Components/Services/Dentures/Dentures';
import ServicesPage from './Components/ServicesPage/ServicesPage';
import CosmeticDentistry from './Components/Services/CosmeticDentistry/CosmeticDentistry';
import DentalHygiene from './Components/Services/DentalHygiene/DentalHygiene';
import DentalImplants from './Components/Services/DentalImplants/DentalImplants';
import CrownsBridges from './Components/Services/CrownsBridges/CrownsBridges';
import DentalVeneers from './Components/Services/DentalVeneers/DentalVeneers';
import EmergencyCare from './Components/Services/EmergencyCare/EmergencyCare';
import TeethWhitening from './Components/Services/TeethWhitening/TeethWhitening';
import TMJ from './Components/Services/TMJ/TMJ';
import RootCanals from './Components/Services/RootCanals/RootCanals';
import Imaging from './Components/Services/Imaging/Imaging';
import Financing from './Components/Financing/Financing';
import Gallery from './Components/Gallery/Gallery';
import AboutUs from './Components/Aboutus/Aboutus';
import UnderConstruction from './Components/UnderConstruction/UnderConstruction';
import WisdomTeeth from './Components/Services/WisdomTeeth/WisdomTeeth';

import {HelmetProvider} from 'react-helmet-async'
import ScrollToTop from "./Components/scrolltoptop"

// change bonegraft to root canal

function App() {



  return (
    
    <HelmetProvider>
    <div>
    <ScrollToTop/>
     <TopPortion/>
     <Navbar/>
     <Routes>
     <Route path="/" element={<HomePage/>}/>
     <Route path="/contact" element={<ContactUs/>}/>
     <Route path="/Completefamilycare" element={<CompleteFamilyCare/>}/>
     <Route path="/Cosmeticdentistry" element={<CosmeticDentistry/>}/>
     <Route path="/Crownsbridges" element={<CrownsBridges/>}/>
     <Route path="/dentalhygiene" element={<DentalHygiene/>}/>
     <Route path="/dentalimplants" element={<DentalImplants/>}/>
     <Route path="/dentures" element={<Dentures/>}/>
     <Route path="/veneers" element={<DentalVeneers/>}/>
     <Route path="/emergencycare" element={<EmergencyCare/>}/>
     <Route path="/photography" element={<Imaging/>}/>
     <Route path="/teethwhitening" element={<TeethWhitening/>}/>
     <Route path="/tmj" element={<TMJ/>}/>
     <Route path="/rootcanals" element={<RootCanals/>}/>
     <Route path="/financing" element={<Financing/>}/>
     <Route path="/services" element={<ServicesPage/>}/>
     <Route path="/gallery" element={<Gallery/>}/>
     <Route path="/wisdomteeth" element={<WisdomTeeth/>}/>
     <Route path="/about" element={<AboutUs/>}/>
     </Routes>
     <Footer/>
     </div>
     </HelmetProvider>
  );
}

export default App;
