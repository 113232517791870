import "./TMJ.css"
import HeaderTitle from "../../../HeaderTitle/HeaderTitle"
import {Fragment} from "react"
import OneCallAway from "../../OneCallAway/OneCallAway"
import { Helmet } from 'react-helmet-async';

function TMJ(props) {
    return (<Fragment>
        <Helmet>
<title>TMJ/TMD Assessment</title>
<meta name='description' content='TMJ' />
<meta name='keywords' content='tmj,teeth whitening, dentist,family dentistry,implants,gallery,family care,cosmetic dentistry,prophylaxis, cleaning, deep cleaning, tartar,dental implants,dental veneers,veneers'/>
<meta name='summary' content='tmj assessment'/> 
<meta name='description' content='tmj assessment'/>
<meta name="title" content="tmj assessment"></meta>
</Helmet>
        <HeaderTitle title="TMJ/TMD Assessment"/>
        <div className="content_holder_services">
            <div className="services_actual_page_header">Believe you have symptoms of TMJ/TMD? We can help, simply contact us to set up an assessment.</div>
           <div className="services_actual_page_text">Do you suffer from headaches? Do you clench or grind your teeth? Do you hear popping or clicking sounds when you move your jaw? Temporomandibular Disorder (TMD) is a general name for a variety of symptoms which can have a variety of causes. It’s often known as TMJ, for Temporomandibular Joint (Syndrome) and is a very common problem in the field of dentistry. Your dentist will assess you to see if you are a candidate for prevention and treatment of the problems associated with the joints and muscles of the jaws.</div>
        

           </div>
        <OneCallAway/>
    </Fragment>)
  }

export default TMJ