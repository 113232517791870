import "./EmergencyCare.css"
import HeaderTitle from "../../../HeaderTitle/HeaderTitle"
import {Fragment} from "react"
import OneCallAway from "../../OneCallAway/OneCallAway"
import { Helmet } from 'react-helmet-async';

function EmergencyCare(props) {
    return (<Fragment>
           <Helmet>
<title>Emergency Care</title>
<meta name='description' content='Emergency Care' />
<meta name='keywords' content='emergency, emergency care, care, dentist,family dentistry,implants,gallery,family care,cosmetic dentistry,prophylaxis, cleaning, deep cleaning, tartar,dental implants,dental veneers,veneers'/>
<meta name='summary' content='dental emergency care'/> 
<meta name='description' content='dental emergency care'/>
<meta name="title" content="dental emergency care"></meta>
</Helmet>
        <HeaderTitle title="Emergency Care"/>
        <div className="content_holder_services">
           
      
            <div className="services_actual_page_header">Need help immediately with your teeth? Don't worry Aben Veinberg DDS is ready for your dental emergency.</div>
           <div className="services_actual_page_text">At Aben Veinberg DDS, we pride ourselves in putting patients first. No one can ever predict a dental emergency, but when it does happen, be assured that we will be ready for you. Many days we can get you in immediately; our flexible schedules and appointment times will allow for you to be seen in a timely manner. Emergencies include, but are not limited to:
           <ul id="cosmetic_list">
<li>Severe tooth pain</li>
<li>Cracked or broken teeth</li>
<li>Knocked out teeth</li>
</ul>
           
           </div>

<div className="services_actual_page_text" >Please call Aben Veinberg DDS if you are experiencing a dental emergency. It is important for you to be seen as soon as possible so we can help you feel better and prevent further problems.</div>
            </div>
        <OneCallAway/>
    </Fragment>)
  }

export default EmergencyCare