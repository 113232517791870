import "./DentalImplants.css"
import HeaderTitle from "../../../HeaderTitle/HeaderTitle"
import {Fragment} from "react"
import OneCallAway from "../../OneCallAway/OneCallAway"
import { Helmet } from 'react-helmet-async';

function DentalImplants(props) {
    return (<Fragment>

<Helmet>
<title>Dental Implants</title>
<meta name='description' content='Dental Implants' />
<meta name='keywords' content='dentist,family dentistry,implants,gallery,family care,cosmetic dentistry,prophylaxis, cleaning, deep cleaning, tartar,dental implants'/>
<meta name='summary' content='Dental Implants'/> 
<meta name='description' content='Dental Implants'/>
<meta name="title" content="Dental Implants"></meta>
</Helmet>
      
        <HeaderTitle title="Dental Implants"/>
        <div className="content_holder_services">
           
          
            <div className="services_actual_page_header" >Dental Implants can improve your smile and are a great alternative to crowns, bridges, or dentures. Ask about implants at Aben Veinberg DDS today!</div>
           <div className="services_actual_page_text">Dental implants are a safe, medically proven, esthetic alternative to traditional bridgework, partials and dentures. Because implant restorations look and feel more like natural teeth, they offer new levels of comfort, security and confidence over traditional treatments. For more than two decades, dental implants have been helping to provide quality of life to patients with restorative needs.</div>
         
  <a id="how_do_they_work_button_toggle" className="toggle_title" data-bs-toggle="collapse" href="#how_it_work" role="button" aria-expanded="false" aria-controls="how_it_work">How do they work?</a>
  <div className="collapse collaspe_body" id="how_it_work">
    <div className="services_actual_page_text collaspe_cancel_margin">
    A dental implant is a small “anchor” made of titanium that is inserted into the jawbone to take the place of a missing tooth root. After osseointegration (when the surrounding bone has healed to the implant), a replacement tooth is secured to the tip of the implant. This new tooth looks, feels and performs similar to natural teeth.
   </div>
</div>
  <a className="toggle_title " data-bs-toggle="collapse" href="#benefits" role="button" aria-expanded="false">What are the benefits?</a>
  <div className="collapse collaspe_body" id="benefits">
    <div className="services_actual_page_text collaspe_cancel_margin">
    A dental implant is a small “anchor” made of titanium that is inserted into the jawbone to take the place of a missing tooth root. After osseointegration (when the surrounding bone has healed to the implant), a replacement tooth is secured to the tip of the implant. This new tooth looks, feels and performs similar to natural teeth.
   </div>
</div>
  <a className="toggle_title" data-bs-toggle="collapse" href="#popular" role="button" aria-expanded="false" >Why have dental implants become so popular?</a>
  <div className="collapse collaspe_body" id="popular">
    <div className="services_actual_page_text collaspe_cancel_margin">
    As the life span increases, the need for some type of permanent dental replacement system becomes very important to overall health. Dentures and partials have obvious problems: They can become loose and unstable. Implants can provide people with dental replacements that are both functional and aesthetic.
   </div>
</div>
  <a className="toggle_title" data-bs-toggle="collapse" href="#anchor" role="button" aria-expanded="false">How long after an implant is placed can it be used to anchor new teeth?</a>
  <div className="collapse collaspe_body" id="anchor">
    <div className="services_actual_page_text collaspe_cancel_margin">
    The protocol that was originally developed clearly states to wait six months in the lower jaw and twelve months in the upper jaw before beginning to construct the new dental prosthesis that will be supported by the implants. In recent years, however, there has been a movement within the profession to speed up this process. Today, it is possible in selected patients to accelerate the healing time. It is even possible to load implants in very specific situations right away. However, the general protocol that is favored is four months in the lower jaw and four months in the upper jaw 
   </div>
</div>
  <a className="toggle_title" data-bs-toggle="collapse" href="#hurt" role="button" aria-expanded="false">Does it hurt to have dental implants placed?</a>
  <div className="collapse collaspe_body" id="hurt">
    <div className="services_actual_page_text collaspe_cancel_margin">
    The actual procedure to surgically place a dental implant is done under local anesthesia and is generally not at all painful. When the anesthesia wears off about three or four hours later, some discomfort may be experienced. The level of discomfort is quite different from patient to patient, but most patients do not have significant problems. Some patients do have varying degrees of pain or discomfort which may last for several days. Swelling along with bruising may also develop. In cases where there is prolonged pain, the dentist should be seen right away. Prolonged pain is not a good sign with dental implants and although it does not always mean failure, the cause of the pain should be determined as soon as possible. If an implant is not properly integrating into the adjacent bone or if an infection develops, the implant may have to be removed.
   </div>
</div>
  <a className="toggle_title" data-bs-toggle="collapse" href="#bridge" role="button" aria-expanded="false">A bridge or a dental implant?</a>
  <div className="collapse collaspe_body" id="bridge">
    <div className="services_actual_page_text collaspe_cancel_margin">
    Perhaps one of the most frequently asked questions is whether or not to use a fixed (“permanent”) bridge or a dental implant to replace one or two missing teeth. Suppose a patient was missing their lower left first molar. If a fixed bridge were to be used, the dentist would cut down the adjacent teeth (the second molar and the second bicuspid) and fit a three unit fixed bridge over those two teeth. The missing tooth would be called a pontic and it would be effectively replaced by the three unit bridge. If the dentist were to use an implant with a crown on it, he or she would place an implant in the site of the original first molar. This could be done immediately or at some date after the first molar was removed. There is no time limit here. The implant will take about four months to connect with the bone and then at that time, the dentist can construct a single crown on the implant to replace the missing first molar.
   </div>
</div>
<video controls="true" muted="true" loop="true" id="implant_video" width="50%">
 <source src="https://s3.us-west-1.amazonaws.com/www.abenveinbergdds.com/videos/implant_video.mp4" />
 </video>





        </div>
        <OneCallAway/>
    </Fragment>)
  }

export default DentalImplants