import "./CosmeticDentistry.css"
import HeaderTitle from "../../../HeaderTitle/HeaderTitle"
import {Fragment} from "react"
import OneCallAway from "../../OneCallAway/OneCallAway"
import { Helmet } from 'react-helmet-async';

function CosmeticDentistry(props) {
    return (<Fragment>

<Helmet>
<title>Cosmetic Dentistry</title>
<meta name='description' content='Cosmetic Dentistry' />
<meta name='keywords' content='dentist,family dentistry,implants,gallery,family care,cosmetic dentistry'/>
<meta name='summary' content='cosmetic dentistry'/> 
<meta name='description' content='cosmetic dentistry'/>
<meta name="title" content="cosmetic dentistry"></meta>
</Helmet>

        <HeaderTitle title="Cosmetic Dentistry"/>
        <div className="content_holder_services">
           
          
            <div className="services_actual_page_header" >Providing quality cosmetic dentistry for the entire family at Aben Veinberg DDS.</div>
           <div className="services_actual_page_text">At Aben Veinberg DDS, we can do a variety of procedures to help your smile cosmetically. Below are the top procedures we can do to help you achieve that one of a kind smile.
<ul id="cosmetic_list">
<li>Bonding</li>
<li>Resin Composites</li>
<li>Indirect Inlays/Onlays</li>
<li>All Ceramic Crowns</li>
<li>Orthodontic Assessment (Adult/Youth)</li>
<li>Partials</li>
<li>Veneers</li>
<li>And Much More</li>
</ul>
</div>
            
            
    
        </div>
        <OneCallAway/>
    </Fragment>)
  }

export default CosmeticDentistry