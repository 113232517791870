import "./WisdomTeeth.css"
import HeaderTitle from "../../../HeaderTitle/HeaderTitle"
import {Fragment} from "react"
import OneCallAway from "../../OneCallAway/OneCallAway"
import { Helmet } from 'react-helmet-async';

function WisdomTeeth(props) {
    return (<Fragment>
         <Helmet>
<title>Wisdom Teeth Extraction</title>
<meta name='description' content='wisdom teeth extraction' />
<meta name='keywords' content='wisdom teeth extraction,teeth whitening, dentist,family dentistry,implants,gallery,family care,cosmetic dentistry,prophylaxis, cleaning, deep cleaning, tartar,dental implants,dental veneers,veneers'/>
<meta name='summary' content='wisdom teeth extraction'/> 
<meta name='description' content='wisdom teeth extraction'/>
<meta name="title" content="wisdom teeth extraction"></meta>
</Helmet>
        <HeaderTitle title="Wisdom Teeth Extraction"/>
        <div className="content_holder_services">
            <div className="services_actual_page_header">Aben Veinberg DDS is an expert at extracting wisdom teeth. He has completed thousands of wisdom teeth extractions throughout his 30 year career.</div>   
            <div className="services_actual_page_text">Wisdom tooth extraction is a surgical procedure to remove one or more wisdom teeth — the four permanent adult teeth located at the back corners of your mouth on the top and bottom.</div>
           <div className="services_actual_page_text">If a wisdom tooth doesn't have room to grow (impacted wisdom tooth), resulting in pain, infection or other dental problems, you'll likely need to have it pulled. Wisdom tooth extraction may be done by a dentist or an oral surgeon.</div>
           <div className="services_actual_page_text">To prevent potential future problems, some dentists and oral surgeons recommend wisdom tooth extraction even if impacted teeth aren't currently causing problems.</div>
           </div>
        <OneCallAway/>
    </Fragment>)
  }

export default WisdomTeeth



