import "./Gallery.css"
import HeaderTitle from "../../HeaderTitle/HeaderTitle"
import {Fragment} from "react"
import OneCallAway from "../OneCallAway/OneCallAway"
import images from "../../resources/services_background.png"
import { Helmet } from 'react-helmet-async';
import image1 from '../../resources/CroppedTeethImages/1.JPG'
import image2 from '../../resources/CroppedTeethImages/2.JPG'
import image3 from '../../resources/CroppedTeethImages/3.JPG'
import image4 from '../../resources/CroppedTeethImages/4.JPG'
import image5 from '../../resources/CroppedTeethImages/5.JPG'
import image6 from "../../resources/beforeandafter.png"

function Gallery(props) {
    return (<Fragment>
      <Helmet>
<title>Gallery</title>
<meta name='description' content='gallery' />
<meta name='keywords' content='dentist,family dentistry,implants,gallery'/>
<meta name='summary' content='Showcasing previous work'/> 
<meta name='description' content='Imaging showing previous work done'/>
<meta name="title" content="gallery"></meta>
</Helmet>
        <HeaderTitle title="Gallery"/>
<div  className="content_holder_services">
            
            <div  id="carouselExampleControls" className="carousel slide before_after_carousel" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={image1} className="d-block img-resp" alt="image 1"/>
    </div>
    <div className="carousel-item">
      <img src={image2} className="d-block  img-resp" alt="image 2"/>
    </div>
    <div className="carousel-item">
      <img src={image3} className="d-block  img-resp" alt="image 3"/>
    </div>
    <div className="carousel-item">
      <img src={image4} className="d-block  img-resp" alt="image 4"/>
    </div>
    <div className="carousel-item">
      <img src={image5} className="d-block img-resp" alt="image 5"/>
    </div>
    <div className="carousel-item">
      <img src={image6} className="d-block img-resp" alt="image 6"/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="false"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="false"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
        </div>
        <OneCallAway/>
    </Fragment>)
  }

export default Gallery