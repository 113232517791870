import "./DentalVeneers.css"
import HeaderTitle from "../../../HeaderTitle/HeaderTitle"
import {Fragment} from "react"
import OneCallAway from "../../OneCallAway/OneCallAway"
import { Helmet } from 'react-helmet-async';

function DentalVeneers(props) {
    return (<Fragment>
      <Helmet>
<title>Veneers</title>
<meta name='description' content='Dental Veneers' />
<meta name='keywords' content='dentist,family dentistry,implants,gallery,family care,cosmetic dentistry,prophylaxis, cleaning, deep cleaning, tartar,dental implants,dental veneers,veneers'/>
<meta name='summary' content='Dental Veneers'/> 
<meta name='description' content='Dental Veneers'/>
<meta name="title" content="Dental Veneers"></meta>
</Helmet>
        <HeaderTitle title="Dental Veneers"/>
        <div className="content_holder_services">
           
        <div className="services_actual_page_text"></div>
            <div className="services_actual_page_header">Improve your smile with dental veneers at Aben Veinberg DDS</div>
           <div className="services_actual_page_text">Often an alternative to crowns, veneers are very thin pieces of specially-shaped porcelain or resin that are cemented over the front of your teeth with little or no anesthesia needed. They are the cure for teeth that are discolored or chipped, have small holes or pits, misshapen or crooked, or for the correction of unwanted or uneven spaces.</div>
           <div className="services_actual_page_text">Unlike crowns, veneers won’t require the dentist to remove much of the tooth itself in most cases. Veneers are created from an impression taken in your dentist’s office. Your custom veneer is then cemented directly onto your tooth</div>
           <div className="services_actual_page_text">This makes veneers a very popular solution for many people seeking that perfect smile. Strong and very durable, veneers can last from ten to fifteen years, and come in colors that will brighten dark teeth without the worry of them changing color.</div>
           <span className="small_title_services">How are Veneers Attached to your Tooth?</span>
           <div className="services_actual_page_text">Teeth are prepared for veneers by lightly buffing to allow for the small added thickness of the veneer. Veneers are thin, like contact lenses, and will usually only need tooth reduction of 0.5mm to 1.0mm. If a drastic change is being made for the result you want, reduction may be 1.0mm to 2.0mm. A mold is taken of the teeth, from which the veneers will be modeled after. Temporary veneers will be placed and worn until your permanent veneers are ready. While wearing the temporary veneers, advise your cosmetic dentist of any adjustments or changes you would like made, such as in shape or size. These changes will be translated into your permanent veneers, so good communication between you and your dentist is important in achieving your new smile. </div>
        </div>
        <OneCallAway/>
    </Fragment>)
  }

export default DentalVeneers