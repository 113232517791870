import "./OneCallAway.css"
import logo_image from "../../resources/logo.png"
import HeaderTitle from "../../HeaderTitle/HeaderTitle"
import {Fragment,useRef} from "react"

function OneCallAway(props) {

  const phoneRefz = useRef(null)

function phoneClick () {

  phoneRefz.current.click()

}

    return (<Fragment>
       <div id="contact_us_for_appointment" >
      <h4 id="title_one_call_away">A Great Smile <br/> Is Only One Call Away</h4>
      <p id="text_under_one_call_away">The time has come. Your perfect smile awaits. You can simply make a call to the number below to get a hold of us and request an appointment. Or take a minute to see what you want to do. Either way we’re here and ready when you need us!</p>
      <p onClick={phoneClick} id="phone_number_contact_us"><i id="phone-icon" className="fa-solid fa-phone"></i> (818) 503-9697</p>
      <a ref={phoneRefz} className="hidden_links" href="tel:1818-503-9697" ></a>
    </div>
    </Fragment>)
  }

export default OneCallAway